import React, { useState, useEffect } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Modal from "components/Modal/Modal.js";
import Notification from "components/Notification/Notification.js";
import AreYouSure from "components/AreYouSure/AreYouSure";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import CreatePageTech from "./CreatePageTech";

import DealerPageTechService from "../../../services/DealerPageTechService";
import DeleteButton from "components/DeleteButton/DeleteButton";
import useDealer from "hooks/useDealer";

export const CREATE_PAGE_TECH = {
    title: "Create Page Settings",
    successMessage: "Page Settings created successfully",
};

export const EDIT_PAGE_TECH = {
    title: "Edit Page Settings",
    successMessage: "Page Settings updated successfully",
};

export default function DealerPageTech({ dlid }) {
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState(CREATE_PAGE_TECH);
    const [openModal, setOpenModal] = useState(false);
    const [techPages, setTechPages] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [notification, setNotification] = useState({
        open: false,
        color: "success",
    });
    const dealer = useDealer();

    const getPageTech = async (dealerId) => {
        const pagetech = await DealerPageTechService.getAll(dealerId);
        setTechPages(pagetech);
    };

    useEffect(() => {
        getPageTech(dlid);
    }, [dlid]);

    const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
            Header: "Page name",
            accessor: "pageName",
        },
        /*{
            Header: "Page tech position",
            accessor: "pagetechPosition",
        },
        {
            Header: "English title",
            accessor: "enTitle",
            Cell: ({ value }) => {
                return (
                    <div style={{ overflowY: "auto", maxHeight: "150px", width: "100%" }}>
                        <div dangerouslySetInnerHTML={{ __html: value }} />
                    </div>
                );
            },
        },

        {
            Header: "English HTML",
            accessor: "enHtml",
            Cell: ({ value }) => {
                return (
                    <div style={{ overflowY: "auto", maxHeight: "150px", width: "100%" }}>
                        <div dangerouslySetInnerHTML={{ __html: value }} />
                    </div>
                );
            },
        },*/
        {
            Header: "Actions",
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                color="warning"
                                className="edit"
                                onClick={() => {
                                    setForm(EDIT_PAGE_TECH);
                                    setOpenModal(true);
                                    setInitialValues(original);
                                }}
                            >
                                <Edit />
                            </Button>
                            <DeleteButton
                                title="Page Settings"
                                handleClick={async () => {
                                    await DealerPageTechService.del(original.id);
                                    setTechPages(
                                        [...techPages].filter((c) => c.id !== original.id)
                                    );
                                }}
                            />
                            {/*<Button
                                justIcon
                                round
                                simple
                                color="danger"
                                className="edit"
                                onClick={() => {
                                    setInitialValues(original);
                                    setOpen(true);
                                }}
                            >
                                <Close />
                            </Button>*/}
                        </div>
                    </>
                );
            },
        },
    ];

    const onSuccess = async () => {
        await getPageTech(dlid);
        setInitialValues({});
        setOpenModal(false);
        setNotification({
            ...notification,
            open: true,
            message: form.successMessage,
        });
    };

    return (
        <>
            {notification.open && (
                <Notification
                    open={notification.open}
                    color={notification.color}
                    message={notification.message}
                    onClose={() =>
                        setNotification({
                            ...notification,
                            open: false,
                        })
                    }
                />
            )}

            <Modal open={openModal} modalTitle={form.title} handleClose={setOpenModal}>
                <div style={{ minWidth: 1200 }}></div>
                <CreatePageTech
                    dlid={dlid}
                    form={form}
                    initialValues={initialValues}
                    languagesAvailable={dealer?.languagesAvailable || ""}
                    onSuccess={(user) => onSuccess(user)}
                />
            </Modal>

            <Button
                color="primary"
                onClick={() => {
                    setForm(CREATE_PAGE_TECH);
                    setOpenModal(true);
                    setInitialValues({});
                }}
            >
                {CREATE_PAGE_TECH.title}
            </Button>

            <ReactTable columns={columns} data={techPages} />

            <AreYouSure
                open={open}
                title={`Are you sure you?`}
                subtitle={`The record will be deleted`}
                onAccept={async () => {
                    await DealerPageTechService.del(initialValues.id);
                    setTechPages([...techPages].filter((c) => c.id !== initialValues.id));
                    setOpen(false);
                }}
                onCancel={() => setOpen(false)}
            />
        </>
    );
}
