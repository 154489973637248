import useCatalogsSwr from "./useCatalogsSwr";

export const SHORTCODES = {
    MAKE: "[MAKE]",
    YEAR: "[YEAR]",
    MODEL: "[MODEL]",
    WHEEL: "[WHEEL]",
    TRIM: "[TRIM]",
    FUEL: "[FUEL]",
    ENGINE: "[ENGINE]",
    CATEGORY: "[CATEGORY]",
    SUBCATEGORY: "[SUBCATEGORY]",
    ITEM_NAME: "[ITEM NAME]",
    PART_NUMBER: "[PART NUMBER]",
    PRICE: "[PRICE]",
};

export const PAGE_IDS = {
    HOME: 1,
    YEARS: 2,
    MODELS: 3,
    TRIMS: 4,
    ENGINES: 5,
    CATEGORIES: 6,
    SUBCATEGORIES: 7,
    ITEM_RESULT: 8,
    ITEM_DETAIL: 9,
    CATEGORY_ITEM_RESULT: 10,
    SUBCATEGORY_ITEM_RESULT: 11,
};

const generatePages = ({ hasTrim, hasWheel }) => [
    { id: PAGE_IDS.HOME, pageName: "Home Page", shortcodes: [] },
    { id: PAGE_IDS.YEARS, pageName: "Catalog flow years", shortcodes: [SHORTCODES.MAKE] },
    {
        id: PAGE_IDS.MODELS,
        pageName: "Catalog flow models",
        shortcodes: [SHORTCODES.MAKE, SHORTCODES.YEAR],
    },
    {
        id: PAGE_IDS.TRIMS,
        pageName: "Catalog flow trims",
        shortcodes: [
            SHORTCODES.MAKE,
            SHORTCODES.YEAR,
            SHORTCODES.MODEL,
            ...(hasWheel ? [SHORTCODES.WHEEL] : []),
            ...(hasTrim ? [SHORTCODES.TRIM] : []),
        ],
    },
    {
        id: PAGE_IDS.ENGINES,
        pageName: "Catalog flow engines",
        shortcodes: [
            SHORTCODES.MAKE,
            SHORTCODES.YEAR,
            SHORTCODES.MODEL,
            ...(hasWheel ? [SHORTCODES.WHEEL] : []),
            ...(hasTrim ? [SHORTCODES.TRIM] : []),
        ],
    },
    {
        id: PAGE_IDS.CATEGORIES,
        pageName: "Catalog flow category",
        shortcodes: [
            SHORTCODES.MAKE,
            SHORTCODES.YEAR,
            SHORTCODES.MODEL,
            ...(hasWheel ? [SHORTCODES.WHEEL] : []),
            ...(hasTrim ? [SHORTCODES.TRIM] : []),
        ],
    },
    {
        id: PAGE_IDS.SUBCATEGORIES,
        pageName: "Catalog flow subcategories",
        shortcodes: [
            SHORTCODES.MAKE,
            SHORTCODES.YEAR,
            SHORTCODES.MODEL,
            ...(hasWheel ? [SHORTCODES.WHEEL] : []),
            ...(hasTrim ? [SHORTCODES.TRIM] : []),
        ],
    },
    {
        id: PAGE_IDS.CATEGORY_ITEM_RESULT,
        pageName: "Category Item Result Page",
        shortcodes: [
            SHORTCODES.MAKE,
            SHORTCODES.YEAR,
            SHORTCODES.MODEL,
            ...(hasWheel ? [SHORTCODES.WHEEL] : []),
            ...(hasTrim ? [SHORTCODES.TRIM] : []),
            SHORTCODES.FUEL,
            SHORTCODES.CATEGORY,
        ],
    },
    {
        id: PAGE_IDS.SUBCATEGORY_ITEM_RESULT,
        pageName: "Subcategory Item Result Page",
        shortcodes: [
            SHORTCODES.MAKE,
            SHORTCODES.YEAR,
            SHORTCODES.MODEL,
            ...(hasWheel ? [SHORTCODES.WHEEL] : []),
            ...(hasTrim ? [SHORTCODES.TRIM] : []),
            SHORTCODES.FUEL,
            SHORTCODES.CATEGORY,
            SHORTCODES.SUBCATEGORY,
        ],
    },
    /*{
        id: PAGE_IDS.ITEM_RESULT,
        pageName: "Item Result Page",
        shortcodes: [
            SHORTCODES.MAKE,
            SHORTCODES.YEAR,
            SHORTCODES.MODEL,
            ...(hasWheel ? [SHORTCODES.WHEEL] : []),
            ...(hasTrim ? [SHORTCODES.TRIM] : []),
            SHORTCODES.FUEL,
            SHORTCODES.CATEGORY,
            SHORTCODES.SUBCATEGORY,
        ],
    },*/
    {
        id: PAGE_IDS.ITEM_DETAIL,
        pageName: "Item Detail",
        shortcodes: [
            SHORTCODES.MAKE,
            SHORTCODES.YEAR,
            SHORTCODES.MODEL,
            ...(hasWheel ? [SHORTCODES.WHEEL] : []),
            ...(hasTrim ? [SHORTCODES.TRIM] : []),
            SHORTCODES.FUEL,
            SHORTCODES.CATEGORY,
            SHORTCODES.SUBCATEGORY,
            SHORTCODES.ITEM_NAME,
            SHORTCODES.PART_NUMBER,
            SHORTCODES.PRICE,
        ],
    },
];

export default function usePageTechDefinitions() {
    const { catalogs = [] } = useCatalogsSwr();

    const isIsuzuFV =
        catalogs.length === 1 && catalogs[0].catalogName?.toLowerCase() === "isuzu_fv";

    const hasTrim = !isIsuzuFV;
    const hasWheel = isIsuzuFV;

    let pages = generatePages({ hasTrim, hasWheel });

    if (isIsuzuFV) {
        const excludedIds = [
            PAGE_IDS.YEARS,
            PAGE_IDS.MODELS,
            PAGE_IDS.TRIMS,
            PAGE_IDS.ENGINES,
            PAGE_IDS.CATEGORIES,
            PAGE_IDS.SUBCATEGORIES,
        ];
        pages = pages.filter((page) => !excludedIds.includes(page.id));
    }

    return { pages };
}
