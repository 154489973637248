import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

// @material-ui/core components
import { Box, Tabs, Tab } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CreatePageTechMeta from "./CreatePageTechMeta";
import CreatePageTechCont from "./CreatePageTechCont";
import usePageTechDefinitions from "hooks/usePageTechDefinitions";

// const
import { CREATE_PAGE_TECH, EDIT_PAGE_TECH } from "./DealerPageTech";

//services
import PageTechService from "../../../services/DealerPageTechService";

const validationSchema = yup.object({
    pageId: yup.string().required(),
});

export default function CreatePageTech({
    dlid = "",
    form = CREATE_PAGE_TECH,
    initialValues,
    languagesAvailable = "",
    onSuccess = () => {},
}) {
    const [tabId, setTabId] = useState(0);
    const { pages } = usePageTechDefinitions();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: initialValues.id || 0,
            pageId: initialValues.pageId || "",

            //PageTechContent
            pagetechPosition: initialValues.pagetechPosition || "",
            pageName: initialValues.pageName || "",
            enTitle: initialValues.enTitle || "",
            esTitle: initialValues.esTitle || "",
            ptTitle: initialValues.ptTitle || "",
            frTitle: initialValues.frTitle || "",
            enHtml: initialValues.enHtml || "",
            esHtml: initialValues.esHtml || "",
            ptHtml: initialValues.ptHtml || "",
            frHtml: initialValues.frHtml || "",

            //EN Meta
            enPageTitle: initialValues.enPageTitle || "",
            enMetaTitle: initialValues.enMetaTitle || "",
            enMetaDescription: initialValues.enMetaDescription || "",
            enMetaKeywords: initialValues.enMetaKeywords || "",

            //EN Meta
            esPageTitle: initialValues.esPageTitle || "",
            esMetaTitle: initialValues.esMetaTitle || "",
            esMetaDescription: initialValues.esMetaDescription || "",
            esMetaKeywords: initialValues.esMetaKeywords || "",

            // PT Meta
            ptPageTitle: initialValues.ptPageTitle || "",
            ptMetaTitle: initialValues.ptMetaTitle || "",
            ptMetaDescription: initialValues.ptMetaDescription || "",
            ptMetaKeywords: initialValues.ptMetaKeywords || "",

            // FR Meta
            frPageTitle: initialValues.frPageTitle || "",
            frMetaTitle: initialValues.frMetaTitle || "",
            frMetaDescription: initialValues.frMetaDescription || "",
            frMetaKeywords: initialValues.frMetaKeywords || "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const { pageName, type = null } = pages.find((p) => p.id == formik.values.pageId);
            console.log("pageName", pageName, "type", type);

            if (form === CREATE_PAGE_TECH)
                createPageTech({ ...values, dealerId: dlid, pageName: pageName });
            if (form === EDIT_PAGE_TECH)
                updatePageTech({ ...values, dealerId: dlid, pageName: pageName });
        },
    });

    const createPageTech = async (body) => {
        try {
            const techPage = await PageTechService.post(body);
            onSuccess(techPage.data);
        } catch (error) {
            console.error(error);
        }
    };

    const updatePageTech = async (body) => {
        try {
            const techPage = await PageTechService.put(body);
            onSuccess(techPage.data);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={formik.handleSubmit}>
                    <GridContainer style={{ marginBottom: 10 }}>
                        <GridItem xs={12} sm={12} md={3}>
                            <FormControl
                                variant="standard"
                                fullWidth
                                style={{ marginTop: 5 }}
                                error={Boolean(formik.errors.pageId)}
                            >
                                <InputLabel id="demo-simple-select-label">Page</InputLabel>
                                <Select
                                    id="pageId"
                                    name="pageId"
                                    value={formik.values.pageId}
                                    onChange={(e) => {
                                        formik.setFieldValue("pageId", e.target.value);
                                        formik.setFieldValue(
                                            "pageName",
                                            pages.find((p) => p.id === e.target.value)?.pageName ||
                                                ""
                                        );
                                    }}
                                    fullWidth
                                >
                                    <MenuItem key={0} value={0}>
                                        Select page
                                    </MenuItem>
                                    {pages.map(({ id, pageName }, _) => (
                                        <MenuItem key={id} value={id}>
                                            {pageName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridItem>
                    </GridContainer>

                    {/* Info short codes */}
                    {formik.values.pageId && formik.values.pageId !== 0 ? (
                        <GridContainer style={{ padding: 16 }}>
                            <GridItem xs={12} sm={12} md={12}>
                                {(() => {
                                    const selectedPage = pages.find(
                                        (p) => p.id === formik.values.pageId
                                    );
                                    if (selectedPage?.shortcodes?.length > 0) {
                                        return (
                                            <Alert variant="outlined" severity="info">
                                                {`Available shortcodes for ${selectedPage.pageName}: `}
                                                <strong>
                                                    {selectedPage.shortcodes.join(", ")}
                                                </strong>
                                            </Alert>
                                        );
                                    } else {
                                        return (
                                            <Alert variant="outlined" severity="warning">
                                                {`${selectedPage?.pageName} does not support any shortcodes.`}
                                            </Alert>
                                        );
                                    }
                                })()}
                            </GridItem>
                        </GridContainer>
                    ) : (
                        ""
                    )}

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Tabs
                                value={tabId}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={(_, newValue) => setTabId(newValue)}
                                TabIndicatorProps={{ style: { background: "#ef662f" } }}
                            >
                                <Tab value={0} label="Meta" />
                                {/*<Tab value={1} label="Page Tech" />*/}
                            </Tabs>

                            <Box padding={2}>
                                {tabId === 0 && (
                                    <CreatePageTechMeta
                                        formik={formik}
                                        languagesAvailable={languagesAvailable}
                                    />
                                )}
                                {/*tabId === 1 && (
                                    <CreatePageTechCont
                                        formik={formik}
                                        languagesAvailable={languagesAvailable}
                                    />
                                )*/}
                            </Box>
                        </GridItem>
                    </GridContainer>

                    <div style={{ textAlign: "center", margin: 10 }}>
                        <Button type="submit" color="rose">
                            Save and close
                        </Button>
                    </div>
                </form>
            </GridItem>
        </GridContainer>
    );
}
